import { useEffect, useState, useRef } from "react";
import "./index.css";
import Btc from "../../assets/elite/btc.svg";
import inflow from "../../assets/elite/inflow.svg";
import ouflow from "../../assets/elite/outflow.svg";
import moment from "moment";
import { Input } from "../../components/Input/index";
import { Empty, notification } from "antd";
import { error, success } from "../../components/Alert/index";
import { Button } from "../../components/Button/index";
import Close from "../../assets/elite/close.svg";
import { Drawer, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import config from "../../config";
export const TableHeader = ({ data, setLoad, load, fetchUsers }) => {
  const [open, setOpen] = useState(false);
  const [payId, setPayId] = useState("");
  const [details, setDetails] = useState({});
  const [fundAmt, setFundAmt]= useState("")
  const [fundModal, setFundModal] = useState(false)
  const [fundAcciD,setFundAccId] = useState("")
  const onClose = () => {
    setOpen(false);
  };

  const formatDec = (num, decimals) =>
    num?.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

  const confirmPay = () => {
    setLoad(true);
    axios
      .get(`${config.baseUrl}confirmUserPay/${payId}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        if (res.status === 200) {
          setOpen(false);
          success(
            "Success!",
            "Payment Confirmed, User has been credited " +
              details?.amount +
              " successfuly!"
          );
        }
      })
      .catch((err) => {
        setLoad(false);
      });
  };

  const flagAcc = (id) => {
    setLoad(true);
    axios
      .put(`${config.baseUrl}user-flag/${id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        setOpen(true);
        if (res.status ===  200) {
          fetchUsers()
            Notification("success", "Success", "User Account flagged successfully");
        }
      })
      .catch((err) => {
        setLoad(false);
      });
  }

  const fundAcc = (id) => {
      setLoad(true);
    axios
      .post(`${config.baseUrl}fundAcc/${fundAcciD}`,{amount: fundAmt, email:fundAcciD}, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        setOpen(true);
    
        if (res.status ===  200) {
          fetchUsers()
          setFundAccId("")
          setFundAmt("")
            Notification("success", "Success", "User Account Funded successfully");
          setFundAmt("")
        }
      })
      .catch((err) => {
        setLoad(false);
      });
  }

const unflagAcc = (id) => {
      axios
      .put(`${config.baseUrl}user-unflag/${id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        setOpen(true);
        if (res.status ===  200) {
          fetchUsers()
            Notification("success", "Success", "User Account Unflagged successfully");
        }
      })
      .catch((err) => {
        setLoad(false);
      });
}

    const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };

    

  const getDetails = (id) => {
    axios
      .get(`${config.baseUrl}user/${id._id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        setOpen(true);
        if (res.data) {
          setDetails(res.data.userInfo);
        }
      })
      .catch((err) => {
        setLoad(false);
      });
  };
  return (
    <div>
      <table>
        <tr>
          <th>S/N</th>
          <th>Name</th>
          <th>Email</th>
                    <th>Status</th>
          <th>Action</th>
        </tr>

        {data.map((data, index) => {
          return (
            <tr>
              <td>{index + 1}</td>
              <td>{data.name}</td>
              <td>{data.email}</td>
              <td>{data.flagStatus}</td>

              <td>
                <div className="d-flex justify-content- align-items-center">
                   <Button
                  text="View Info "
                  className="dark"
                  isDisabled={load}
                  onClick={() => {
                    console.log("clicked this");
                    getDetails(data)
                  }}
                />
                    <Button
                  text={data.flagStatus === 'Active' ? 'flag' : 'unflag'}
                  className="dark"
                  isDisabled={load}
                  onClick={(e) => {
                     e.preventDefault();
    Modal.confirm({
      title: `Are you sure you want to ${data.flagStatus === 'Active' ? 'flag' : 'unflag'} this user account ? `,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        if(data.flagStatus !== 'Active'){
          unflagAcc(data?._id)
        }
        else{
                            flagAcc(data?._id);
        }
      },
      onCancel() {},
    });
                  }}
                />
                <Button
                  text="Fund "
                  className="dark"
                  isDisabled={load}
                  onClick={() => {
                    console.log("clicked this");
                    setFundModal(true)
                    setFundAccId(data?.email);
                  }}
                />
                  
                </div>
              
              </td>
            </tr>
          );
        })}
      </table>

      <Drawer
        title={false}
        placement="right"
        onClose={onClose}
        open={open}
        closable={false}
      >
        <div className="text-right pb-3" onClick={onClose}>
          <img src={Close} width="20" />
        </div>
        <br />
        <div>
          <div>User information</div>
          
          <div className="d-flex justify-content-between align-items-center">
            <div>Password</div>
            <div>{details.password}</div>
          </div>
          <br />

          <div className="d-flex justify-content-between align-items-center">
            <div>Address</div>
            <div>{details?.address}</div>
          </div>
          <br />
          <div className="d-flex justify-content-between align-items-center">
            <div>State</div>
            <div>{details?.state}</div>
          </div>
          <br />
          <div className="d-flex justify-content-between align-items-center">
            <div>Country</div>
            <div>{details?.country}</div>
          </div>
          <br />
          <div className="d-flex justify-content-between align-items-center">
            <div>Wallet Balance</div>
            <div>${formatDec(details.wallet)}</div>
          </div>

          <br />
          <br />
          <div className="d-flex justify-content-between align-items-center">
            <Button
              text="Block"
              className="cancel w-50"
            />
          
          </div>
        </div>
        <hr />
        <div>
          <div>KYC information</div>
          <div className="d-flex justify-content-between align-items-center">
            <div>Identitificaton Type</div>
            <div>{details.identityType}</div>
          </div>
          <br />
          <div className="d-flex justify-content-between align-items-center">
            <div>KYC Uploaded Image</div>
          </div>
          <div>
            <img className="w-100 pt-3 pb-2" src={details?.identityImage} />
          </div>
        </div>
        <hr />
        <div>
          <div>Bank Account</div>
          <div className="d-flex justify-content-between align-items-center">
            <div>Account Number</div>
            <div>{details.accNum}</div>
          </div>
          <br />
          <div className="d-flex justify-content-between align-items-center">
            <div>Account Type</div>
            <div>{details.accType}</div>
          </div>
          <br />
          <div className="d-flex justify-content-between align-items-center">
            <div>Routing Number</div>
            <div>{details.routing}</div>
          </div>
          <br />
          <div className="d-flex justify-content-between align-items-center">
            <div>Bank </div>
            <div>{details.bankName}</div>
          </div>
        </div>
      </Drawer>

        <Modal title="Fund User Wallet" open={fundModal} footer={false} onCancel={() => {
      setFundModal(false)
       }}>
         <div className="">
                <Input
                  label="Amount"
                  type="text"
                  value={fundAmt}
                  placeholder="Input Amount"
                  className="w-100"
                  onChange={(e) => setFundAmt(e.target.value)}
                />
              </div>
         <br />
       <Button
                text={"Submit"}
                style={{ borderRadius: "5px" }}
                className="dark w-100"
                onClick={fundAcc}
              />
      </Modal>
    </div>
  );
};
export default TableHeader;
