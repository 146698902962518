import { useEffect, useState, useRef } from "react";
import "./index.css";
import Btc from "../../assets/elite/btc.svg";
import star from "../../assets/elite/start.svg";

export const TableHeader = ({ data }) => {
  const formatDec = (num, decimals) =>
    num?.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  return (
    <div>
      <table>
        <tr>
          <th>Name</th>
          <th>Price</th>
          <th>Supply</th>
          <th>Market Cap</th>
          <th>Watch</th>
        </tr>

        {data?.map((t) => {
          return (
            <tr>
              <td>
                <div className="d-flex align-items-center">
                  <div>
                    {
                      {
                        BTC: <img src={Btc} width={25} />,
                        ETH: <img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSKjnYnbk5aCztGK5nbizw3wuhwtzehfUdbAg&s"}  width={25} />,
                        USDT: <img src={"https://cdn.vectorstock.com/i/500p/53/60/tether-usdt-crypto-coin-ic-vector-20865360.jpg"}  width={25} />,
                        BNB: <img src={"https://w7.pngwing.com/pngs/1007/775/png-transparent-bnb-cryptocurrencies-icon.png"} width={25}  />,
                        SOL: <img src={"https://cryptologos.cc/logos/solana-sol-logo.png"} width={25}  />,

                      }[t?.symbol]
                    }
                  </div>
                  <div className="pl-2">
                    <div className="td-tile">{t?.name}</div>
                    <div style={{ fontWeight: 200, color: "#7A899A" }}>
                      {t?.symbol}
                    </div>
                  </div>
                </div>
              </td>
              <td>${formatDec(t?.priceUsd?.substring(0, 6))}</td>
              <td style={{ color: "#4BA582" }}>
                ${parseInt(t?.supply.substring(0, 6))}
              </td>
              <td>{formatDec(t?.marketCapUsd.substring(0, 6))}</td>
              <td>{formatDec(t?.changePercent24Hr.substring(0, 6))}</td>
            </tr>
          );
        })}
      </table>
    </div>
  );
};
export default TableHeader;
